<template>
    <div class="signin-page">

      <div class="signin-section-1">
        <div class="aplica-spacer">

        </div>
        <img
          src="../../src/assets/img/vissia-logo2.png"
          class="login-logo-color"
        />
        <a href="https://invite.vissia.ro/utilizator" target="_blank">
          <div class="aplica-spacer">

</div>
        <div class="aplica-btn-u2">
            Afla mai multe
        </div>
      </a>
      <div class="aplica-spacer">

</div>
      <div class="video-bg-signup">

        
<video-player 
src="https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/images/agentclipfinal.mp4"
controls
class="vjs-big-play-centered"
:fluid="true"
width=100%
height=100%
/>
</div>
        <div class="aplicatie-firma">
            Aplica pentru invitatie utilizator
        </div>

        <div class="success-apply" v-if="successOn">
            Aplicatie inregistrata cu succes!
        </div>
  
        <form ref="form" @submit.prevent="onSubmit" class="login-form" v-if="!successOn">
            <div class="input-field">
            <label>Nume</label>
            <input type="text" v-model="nume" />
          </div>
         
          

          
          <div class="input-field">
            <label>Email</label>
            <input type="email" v-model="email" />
          </div>
          <div class="error-login-row">
            <div class="error-text">
              <span v-if="emailPassError"
                >Trebuie sa completati toate campurile!</span
              >
              <span v-if="emailPassWrong">Email sau parola gresita!</span>
            </div>
          </div>
          <button type="submit" class="signin-button" @click.prevent="trimiteAplicatie()">
            Trimite aplicatie
          </button>
  

        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { supabase } from "../../src/clients/supabase";
  import { ref, computed, onMounted } from "vue";
  import { useStore } from "vuex";
import router from '@/router';

  
  export default {
    name: "AplicaUtilizator",
    components: {},
    data() {
      return {
        error: false,
      };
    },
    setup() {
      const store = useStore();

  
      const isAuth = computed(() => {
        return store.getters["user/isAuth"];
      });
  
      const isAdmin = computed(() => {
        return store.getters["user/isAdmin"];
      });
  
      onMounted(() => {

        
      })
  
      const userRole = ref("");
      const userId = ref("");
      const nume = ref('')
      const experienta = ref('')
      const profil = ref('')
      const telefon = ref('')
      const email = ref('')

      const successOn = ref(false)
      
      
      const emailPassError = ref(false);
      const emailPassWrong = ref(false);
  

    

  
    
  
      const trimiteAplicatie = async () => {
        if(nume.value === '' || email.value === '') {
            emailPassError.value = true
            setTimeout(() => {
                emailPassError.value = false
            }, 3000);
            return
        }
        const { data, error } = await supabase
          .from("aplicatiiutilizator")
          .insert({
            nume: nume.value,
            experienta: '-',
            profil: '-',
            telefon: '-',
            email: email.value
          })
          
  
        if (error) {
          console.log(error);
        } else {
            nume.value = ''
            experienta.value = ''
            profil.value = ''
            telefon.value = ''
            email.value = ''


          console.log(data);
          successOn.value = true
          setTimeout(() => {
            successOn.value = false
            router.push('/utilizator')
          }, 3000);
          
  
          
        }
      };
  

  
      return {
        userRole,

        isAuth,
        isAdmin,
        userId,
        successOn,
        store,
        emailPassError,
        emailPassWrong,
        nume,
        experienta,
        profil,
        telefon,
        email,
        trimiteAplicatie
      };
    },
    methods: {},
  };
  </script>
  