/* eslint-disable */
<template>
  <div class="page-container">
    <div class="main-page-container">
        <div class="main-page-title">
            <img src="../assets/img/vissia-logo-green.png" class="mainpagelogo">
        </div>
        <div class="main-page-row">
            <router-link to="/firma" class="linkinvite1">
            <div class="firma-side">
                Firma
            </div>
        </router-link>
        <router-link to="/utilizator" class="linkinvite">
            <div class="utilizator-side">
                Utilizator
            </div>
        </router-link>
        </div>
    </div>

  </div>

</template>

<script>
// @ is an alias to /src

export default {
  name: "FirmaPage",
  components: {},
  data() {
    return {
      options: {
        toolbar: false,
        navbar: false,
        title: false,
        movable: false
      },
      pasSelectat: 0
    };
  },
  created() {},
  methods: {},
};
</script>

<style>
@import "../css/mainpage.css";

</style>