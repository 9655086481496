import { createRouter, createWebHistory } from 'vue-router'

import SignUp from '../views/SignUp.vue'
import HomePage from '../views/HomePage.vue'
import FirmaPage from '../views/FirmaPage.vue'
import AplicaUtilizator from '../views/AplicaUtilizator.vue'
import UtilizatorPage from '../views/UtilizatorPage.vue'







const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
    {
      path: '/signup',
      name: 'SignUp',
      component: SignUp,
    },
    {
      path: '/firma',
      name: 'FirmaPage',
      component: FirmaPage,
    },
    {
      path: '/utilizator',
      name: 'UtilizatorPage',
      component: UtilizatorPage,
    },
    {
      path: '/aplica',
      name: 'AplicaUtilizator',
      component: AplicaUtilizator,
    },
  

   
]



const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
      // always scroll to top
      return { top: 0 }
    },
  })



export default router