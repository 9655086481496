/* eslint-disable */
<template>
  <div class="page-container">
   <div class="u-header-section">
    <div class="u-header-row">
        <div class="u-header-col1">
            <div class="u-header-img-row">
                <img src="../assets/img/users-hp-u.png" class="header-img-u">
                
            </div>
        </div>
        <div class="u-header-col2">
            <div class="u-mini-title">
                Ce este Vissia?
            </div>
            <div class="u-large-title">
                Vissia iti ofera o noua oportunitate de generat venituri
            </div>
            <div class="u-small-text">
                Cu totii recomandam diverse servicii si produse prietenilor, cunoscutilor sau altor persoane cu care intram in contact in diferite situatii sau contexte. Cum ar fi sa castigi si tu din toate aceste recomandari?
            </div>
            <div class="u-col2-row">
                <div class="u-header-lil-col">
                    <div class="u-lil-title">
                        Usor de folosit
                    </div>
                    <div class="u-small-text">
                        Am creat cel mai simplu proces pentru utilizatori. Doar trimiti recomandarea catre firma si apoi astepti sa iti intre comisionul in cont. Dashboard-ul este intuitiv si ai toate informatiile disponibile la un click distanta!
                    </div>
                </div>
                <div class="u-header-lil-col2">
                    <div class="u-lil-title">
                        Servicii si produse diverse
                    </div>
                    <div class="u-small-text">
                        Avem o gama foarte variata de servicii si produse pe care le poti recomanda. Incercam sa acoperim cat mai multe din nevoile potentialilor clienti, pentru ca tu sa poti comisiona din orice au nevoie cei din jurul tau!
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="u-section2">
        <div class="u-section2-row">
            <div class="u-section2-col1">
                <div class="u-title">
                    Cum castigi bani ca utilizator?
                </div>
                <div class="u-section2-text">
                    Ca utilizator al platformei Vissia ai doua modalitati de a genera venituri:
                </div>
                <span class="u-bp">Sa trimiti recomandari catre firmele listate</span>
                <span class="u-bp">Sa inviti membri noi pe platforma</span>
            </div>
            <div class="u-section2-col2">
                <img src="../assets/img/iconimg.png" class="u-va-icon">
            </div>
        </div>
        
    </div>
    <div class="u-section4">
        <router-link to="/aplica">
        <div class="aplica-btn-u">
            Aplica acum!
        </div>
    </router-link>
        
    </div>
    <div class="u-section3">
        <div class="u-title2">
            Trimite recomandari catre firmele listate
        </div>
        <div class="u-section3-row">
            <div class="u-section3-col1">
                <viewer v-viewer :options="options">
                <img src="../assets/img/u-firme.png" class="u-firme-img">
            </viewer>
            </div>
            <div class="u-section3-col2">
                <div class="u-s3-title">
                    Lista firmelor
                </div>
                <div class="u-s3-text">
                    Pe pagina portofoliului nostru de firme gasesti numele firmelor listate, domeniul de activitate, si comisionul oferit. Toate firmele listate pe platforma Vissia ofera un comision, intre 2% si 15%. 
                </div>
                <div class="u-s3-title2">
                    Pagini de profil
                </div>
                <div class="u-s3-text">
                    Fiecare firma are deasemenea o pagina de profil, unde poti gasi materiale de marketing, precum prezentari PDF, descrierea firmei, recomandari de vanzari, puncte forte, exemple de incasari si galerie foto. 
                </div>
            </div>
        </div>
        <div class="u-s3-row2">
        <div class="u-s3-col3">
            <div class="u-s3-title2">
                    Cum functioneaza?
                </div>
                <div class="u-s3-text">
                    Pe dashboard ai un formular scurt pentru a trimite recomandari catre firmele listate. Sa presupunem ca cineva cunoscut are nevoie de un website. Le spui ca stii pe cineva care face site-uri foarte bune, intri pe platforma Vissia, completezi formularul cu numele prietenului tau si il trimiti catre una din firmele noastre care fac pagini web. Firma il contacteaza pe prietenul tau, isi fac treaba, iar tu vei putea urmari pe platforma valoarea contractului semnat intre ei si comisionul care iti revine tie. Cand firma incaseaza banii de la client, tu iti primesti comisionul direct in cont!
                </div>
        </div>
        <div class="u-s3-col4">
            <viewer v-viewer :options="options">
                <img src="../assets/img/onboarding-firma-page.png" class="u-firme-img">
            </viewer>
            </div>
        </div>
    
    </div>

    <div class="u-section5">
        <div class="u-title2">
            Invita membri noi pe platforma
        </div>
        <div class="u-section3-row">
            <div class="u-section3-col1">
                
                <div class="u-s3-title2">
                    Acces doar pe baza de invitatie
                </div>
                <div class="u-s3-text">
                    Ca sa ai acces la platforma Vissia trebuie sa <span class="u-ga">primesti o invitatie</span> de la cineva care este deja membru, sau direct de la noi, aplicand prin site, sau prin diferitele campanii pe care le derulam. 
                </div>
            </div>
            <div class="u-section3-col2">
                <div class="u-s3-title">
                    Procent de la cei invitati
                </div>
                <div class="u-s3-text">
                    Atunci cand inviti pe cineva pe platforma, vei <span class="u-ga">incasa 10%</span> din toate comisioanele pe care le genereaza persoana respectiva. Foarte important de retinut ca nu vei castiga nimic si de la persoanele pe care aceasta le invita la randul ei. Vissia <b>nu este</b> un MLM. Invitatiile se pot cumpara oricand de pe platforma, in limita locurilor disponibile, sau pot fi primite prin diverse promotii.
                </div>
               
            </div>
            
        </div>
        <div class="u-section3-row">
            
        </div>
        <div class="u-s3-row2">
        <div class="u-s3-col3">
            
        </div>
        <div class="u-s3-col4">
            
            </div>
        </div>
    
    </div>
    <div class="u-section4">
        <router-link to="/aplica">
        <div class="aplica-btn-u">
            Aplica acum!
        </div>
    </router-link>
        
    </div>
   </div>
   
  </div>

</template>

<script>
// @ is an alias to /src

export default {
  name: "UtilziatorPage",
  components: {},
  data() {
    return {
      options: {
        toolbar: false,
        navbar: false,
        title: false,
        movable: false
      },
      pasSelectat: 0
    };
  },
  created() {},
  methods: {},
};
</script>
